import { format } from "date-fns";

const localizeDate = (rawDateString: string) => {
  let date = new Date(rawDateString);

  // convert date from utc to local
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

  return date;
};

export const loadAndFormatDate = (rawDateString: string) => {
  // Parse the ISO format date
  const date = localizeDate(rawDateString);

  // Format the date in a human-readable format
  const formattedDate = format(date, "MMM d, yyyy h:mm a");

  return formattedDate;
};
