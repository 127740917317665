import { EmailStatus, OutboundEmailStatus } from "@/types";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { loadAndFormatDate } from "@/utils/date";
import { PaperPlaneIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { resendWebhook } from "@/utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { cn } from "@/lib/utils";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

export const EmailStatusBadge = (props: {
  status: EmailStatus;
  status_message: string | null;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          className={cn(
            props.status === "valid"
              ? "bg-green-500 hover:bg-green-500"
              : "bg-red-500 hover:bg-red-500",
            "text-white cursor-default"
          )}
        >
          {props.status}
        </Badge>
      </TooltipTrigger>
      {props.status_message && (
        <TooltipContent>{props.status_message}</TooltipContent>
      )}
    </Tooltip>
  );
};

export const OutgoingEmailStatusBadge = (props: {
  status: OutboundEmailStatus;
}) => {
  return (
    <Badge
      className={cn(
        props.status === "pending"
          ? "bg-yellow-500 hover:bg-yellow-500"
          : "bg-green-500 hover:bg-green-500",
        "text-white cursor-default"
      )}
    >
      {props.status}
    </Badge>
  );
};

const ResendWebhookDialog = (props: {
  emailId: string;
  existingWebhookUrl: string;
}) => {
  const authInfo = useAuthInfo();
  const [open, setOpen] = useState(false);
  const [resending, setResending] = useState(false);
  const [newWebhookUrl, setNewWebhookUrl] = useState(props.existingWebhookUrl);

  useEffect(() => {
    setNewWebhookUrl(props.existingWebhookUrl);
  }, [props.existingWebhookUrl]);

  const handleResend = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setResending(true);
    const response = await resendWebhook(
      props.emailId,
      newWebhookUrl,
      authInfo.accessToken ?? null
    );
    setResending(false);
    if (response === null) {
      toast.error("Failed to resend webhook");
    } else if (response.status_code !== null && response.status_code < 400) {
      toast.success("Webhook resent");
      setOpen(false);
    } else {
      toast.error("Webhook resent but server returned error");
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary" size="icon" className="h-6 w-6">
          <PaperPlaneIcon className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[600px] max-h-[90%] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>Resend Webhook</DialogTitle>
          <DialogDescription>
            You can override the default webhook URL for this particular request
          </DialogDescription>
        </DialogHeader>
        <Input
          value={newWebhookUrl}
          onChange={(e) => setNewWebhookUrl(e.target.value)}
        />
        <DialogFooter>
          <Button onClick={handleResend} disabled={resending}>
            {resending && <ReloadIcon className="w-4 h-4 animate-spin mr-2" />}
            Send Webhook
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const WebhookStatusBadge = (props: {
  emailId: string;
  webhookUrl: string | null;
  statusCode: number | null;
  createdAt: string | null;
}) => {
  const validStatusCode =
    props.statusCode !== null &&
    props.statusCode >= 200 &&
    props.statusCode < 400;

  return (
    <div className="flex items-center space-x-1">
      {props.createdAt && (
        <Tooltip>
          <TooltipTrigger>
            <Badge
              className={cn(
                validStatusCode
                  ? "bg-green-500 hover:bg-green-500"
                  : "bg-red-500 hover:bg-red-500",
                "text-white cursor-default"
              )}
            >
              {props.statusCode ?? "Failed"}
            </Badge>
          </TooltipTrigger>
          <TooltipContent>{loadAndFormatDate(props.createdAt)}</TooltipContent>
        </Tooltip>
      )}
      {props.webhookUrl && (
        <Tooltip>
          <TooltipTrigger>
            <ResendWebhookDialog
              emailId={props.emailId}
              existingWebhookUrl={props.webhookUrl}
            />
          </TooltipTrigger>
          <TooltipContent>Resend Webhook</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};
