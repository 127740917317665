import DOMPurify from "dompurify";
import { useIsMobile } from "@/hooks/use-mobile";
import { EmailAddress, EmailAttachment, EmailPayload } from "@/types";
import { downloadData, getEmailPayload } from "@/utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Sheet, SheetContent, SheetTitle } from "@/components/ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { DownloadIcon } from "@radix-ui/react-icons";
import { LoadingView } from "@/components/Loader";
import { loadAndFormatDate } from "@/utils/date";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

const EmailContentDisplay = (props: { emailData: EmailPayload }) => {
  return props.emailData.html ? (
    <div
      dangerouslySetInnerHTML={{
        __html: `
                        <style>
          body {
            max-width: 100%;
            overflow-wrap: break-word;
          }
          img, table {
            max-width: 100%;
            height: auto;
                  }
                </style>
                  ${DOMPurify.sanitize(props.emailData.html)}
                `,
      }}
    />
  ) : (
    <div>{props.emailData.plain_text}</div>
  );
};

const formatEmailAddress = (emailAddress: EmailAddress) => {
  return emailAddress.name
    ? `${emailAddress.name} <${emailAddress.address}>`
    : emailAddress.address;
};

const MultiEmailAddressDisplay = (props: {
  fieldName: string;
  emailAddresses: EmailAddress[];
}) => {
  return (
    <div className="flex items-center space-x-2 text-sm">
      <div className="font-bold">{props.fieldName}:</div>
      <Tooltip>
        <TooltipTrigger>
          <div className="flex items-center space-x-2 max-w-full truncate text-ellipsis">
            {props.emailAddresses
              .map((emailAddress) => formatEmailAddress(emailAddress))
              .join(", ")}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <div className="flex flex-col gap-2">
            {props.emailAddresses.map((emailAddress) => (
              <div key={emailAddress.address}>
                {formatEmailAddress(emailAddress)}
              </div>
            ))}
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

const EmailHeader = (props: { emailData: EmailPayload }) => {
  return (
    <div className="space-y-1 max-w-[250px] md:max-w-[600px]">
      <Tooltip>
        <TooltipTrigger>
          <div className="text-md font-semibold max-w-[250px] md:max-w-[400px] truncate text-ellipsis">
            {props.emailData.subject}
          </div>
        </TooltipTrigger>
        <TooltipContent>{props.emailData.subject}</TooltipContent>
      </Tooltip>
      <div className="text-sm text-gray-500">
        {loadAndFormatDate(props.emailData.date)}
      </div>
      <MultiEmailAddressDisplay
        fieldName="From"
        emailAddresses={[props.emailData.from_address]}
      />
      {props.emailData.to_addresses.length > 0 && (
        <MultiEmailAddressDisplay
          fieldName="To"
          emailAddresses={props.emailData.to_addresses}
        />
      )}
      {props.emailData.cc_addresses.length > 0 && (
        <MultiEmailAddressDisplay
          fieldName="Cc"
          emailAddresses={props.emailData.cc_addresses}
        />
      )}
      {props.emailData.bcc_addresses.length > 0 && (
        <MultiEmailAddressDisplay
          fieldName="Bcc"
          emailAddresses={props.emailData.bcc_addresses}
        />
      )}
    </div>
  );
};

const AttachmentDisplay = (props: {
  attachment: EmailAttachment;
  handleDownload: (suffix: string) => void;
  emailId: string;
  outbound: boolean;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="default"
          size="sm"
          className="w-[150px]"
          onClick={() =>
            props.handleDownload(
              `${props.outbound ? "outbound/" : ""}${props.emailId}/attachment/${props.attachment.id}`
            )
          }
        >
          <div className="w-[150px] truncate text-ellipsis">
            {props.attachment.filename}
          </div>
        </Button>
      </TooltipTrigger>
      <TooltipContent className="max-w-[300px]">
        <div className="flex flex-col gap-2">
          <span className="font-bold">Filename:</span>{" "}
          {props.attachment.filename}
          <span className="font-bold">Content Type:</span>{" "}
          {props.attachment.content_type}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

const AttachmentsDisplay = (props: {
  attachments: EmailAttachment[];
  handleDownload: (suffix: string) => void;
  emailId: string;
  outbound: boolean;
}) => {
  return (
    <div className="space-y-1">
      <div className="font-bold">Attachments</div>
      <div className="flex items-center space-x-2 w-full overflow-x-auto">
        {props.attachments.map((attachment) => (
          <AttachmentDisplay
            key={attachment.id}
            attachment={attachment}
            handleDownload={props.handleDownload}
            emailId={props.emailId}
            outbound={props.outbound}
          />
        ))}
      </div>
    </div>
  );
};

export const EmailDisplay = (props: {
  emailId: string | null;
  setEmailId: (emailId: string | null) => void;
  outbound: boolean;
}) => {
  const authInfo = useAuthInfo();
  const isMobile = useIsMobile();
  const [emailData, setEmailData] = useState<EmailPayload | null>(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.emailId) {
      setOpen(true);
    }
  }, [props.emailId]);

  useEffect(() => {
    if (props.emailId) {
      setEmailLoading(true);
      getEmailPayload(
        props.emailId,
        authInfo.accessToken ?? null,
        props.outbound
      ).then((response) => {
        if (response !== null) {
          setEmailData(response);
        } else {
          toast.error("Failed to fetch email data");
        }
        setEmailLoading(false);
      });
    }
  }, [props.emailId]);

  const handleDownload = async (suffix: string) => {
    const success = await downloadData(suffix, authInfo.accessToken ?? null);
    if (!success) {
      toast.error("Failed to download");
    }
  };

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          props.setEmailId(null);
        }
        setOpen(open);
      }}
    >
      <SheetContent
        style={{
          maxWidth: isMobile ? "100vw" : "50vw",
          width: isMobile ? "100%" : undefined,
          right: isMobile ? 0 : undefined,
        }}
        className="overflow-y-auto"
      >
        <VisuallyHidden asChild>
          <SheetTitle>{emailData?.subject || "Email Details"}</SheetTitle>
        </VisuallyHidden>
        <div className="space-y-4 pt-4">
          <div className="flex items-start justify-between">
            {emailData && <EmailHeader emailData={emailData} />}
            {!props.outbound && (
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="default"
                    onClick={() => handleDownload(`${props.emailId}/raw`)}
                  >
                    <DownloadIcon className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Download Email</TooltipContent>
              </Tooltip>
            )}
          </div>
          {emailData && emailData.attachments.length > 0 && props.emailId && (
            <AttachmentsDisplay
              attachments={emailData.attachments}
              handleDownload={handleDownload}
              emailId={props.emailId}
              outbound={props.outbound}
            />
          )}
          <div>
            {emailLoading ? (
              <LoadingView text="Loading email..." />
            ) : emailData ? (
              <EmailContentDisplay emailData={emailData} />
            ) : null}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
