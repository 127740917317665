import {
  ControlFromAddress,
  Domain,
  EmailOverview,
  EmailPayload,
  EmailWebhookLog,
  InboundEmailMetadata,
  InboxConfiguration,
  OutboundEmailMetadata,
  OutboundEmailOverview,
  UserInfo,
} from "@/types";
import Ajax, { RequestError } from "./Ajax";
import { toast } from "sonner";

let baseUrl = "";
if (import.meta.env.VITE_ENV === "prod") {
  baseUrl = "https://api.botmailroom.com";
}

export const getEmailData = async (
  validOnly: boolean,
  inboxIds: string[] | null,
  fromAddresses: string[] | null,
  searchTerm: string | null,
  accessToken: string | null
) => {
  let response = null;
  try {
    let url = `${baseUrl}/api/v1/email?valid_only=${validOnly}`;
    if (searchTerm) {
      url += `&search_term=${searchTerm}`;
    }
    if (inboxIds) {
      inboxIds.forEach((inboxId) => {
        url += `&inbox_ids=${inboxId}`;
      });
    }
    if (fromAddresses) {
      fromAddresses.forEach((fromAddress) => {
        url += `&from_addresses=${fromAddress}`;
      });
    }
    response = await Ajax.req<EmailOverview[]>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getOutboundEmailData = async (
  inboxIds: string[] | null,
  searchTerm: string | null,
  accessToken: string | null
) => {
  let response = null;
  try {
    let url = `${baseUrl}/api/v1/email/outbound`;
    if (inboxIds) {
      inboxIds.forEach((inboxId) => {
        url += `&inbox_ids=${inboxId}`;
      });
    }
    if (searchTerm) {
      url += `&search_term=${searchTerm}`;
    }
    // replace the first character with a ?
    url = url.replace(/&/, "?");

    response = await Ajax.req<OutboundEmailOverview[]>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const upsertInboxConfiguration = async (
  inboxConfiguration: Omit<
    InboxConfiguration,
    "id" | "updated_at" | "webhook_secret_preview"
  > & {
    webhook_secret: string | null;
  },
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{
      id: string;
      webhook_secret: string | null;
      webhook_secret_preview: string | null;
    }>({
      url: `${baseUrl}/api/v1/inbox/upsert`,
      method: "POST",
      accessToken,
      body: inboxConfiguration,
    });
  } catch (error) {
    console.error(error);
    if (error instanceof RequestError && error.response.status === 429) {
      toast.error(
        "You have created the maximum number of inboxes based on your current plan."
      );
    } else {
      toast.error("Failed to create inbox");
    }
  }
  return response;
};

export const getInboxConfigurations = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<InboxConfiguration[]>({
      url: `${baseUrl}/api/v1/inbox`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getInboxDomains = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{ name: string; verified: boolean }[]>({
      url: `${baseUrl}/api/v1/domain/valid-names`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getEmailPayload = async (
  emailId: string,
  accessToken: string | null,
  outbound: boolean
) => {
  let response = null;
  try {
    response = await Ajax.req<EmailPayload>({
      url: `${baseUrl}/api/v1/email/${outbound ? "outbound/" : ""}${emailId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const resendWebhook = async (
  emailId: string,
  webhookUrl: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<EmailWebhookLog>({
      url: `${baseUrl}/api/v1/email/${emailId}/resend-webhook`,
      method: "POST",
      accessToken,
      body: { webhook_url: webhookUrl },
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getNewWebhookSigningSecret = async (
  inboxId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{
      secret: string;
      secret_preview: string;
    }>({
      url: `${baseUrl}/api/v1/inbox/configuration/${inboxId}/webhook/signing-secret`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getHasApiKey = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{ has_api_key: boolean }>({
      url: `${baseUrl}/api/v1/user/has-api-key`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getEmailAddresses = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<string[]>({
      url: `${baseUrl}/api/v1/inbox/individual-inbox-email-address`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const markEmail = async (
  emailId: string,
  valid: boolean,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<EmailOverview>({
      url: `${baseUrl}/api/v1/email/${emailId}/mark/${valid}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const checkAddressAvailability = async (
  address: string,
  configurationId: string | null,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ available: boolean }>({
      url: `${baseUrl}/api/v1/inbox/check-address-availability`,
      method: "POST",
      accessToken,
      body: { address, inbox_id: configurationId },
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDomains = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Domain[]>({
      url: `${baseUrl}/api/v1/domain`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const verifyDomain = async (
  domainId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/domain/${domainId}/verify`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const checkDomainAvailability = async (
  domain: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ available: boolean }>({
      url: `${baseUrl}/api/v1/domain/check-availability`,
      method: "POST",
      accessToken,
      body: { domain },
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const createDomain = async (
  domain: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Domain>({
      url: `${baseUrl}/api/v1/domain/create`,
      method: "POST",
      accessToken,
      body: { domain },
    });
  } catch (error) {
    console.error(error);
    if (error instanceof RequestError && error.response.status === 429) {
      toast.error(
        "You have created the maximum number of custom domains based on your current plan."
      );
    } else {
      toast.error("Failed to create domain");
    }
  }
  return response;
};

export const downloadData = async (
  suffix: string,
  accessToken: string | null | undefined
) => {
  let success = true;
  try {
    const response = await Ajax.req<{ filename: string; blob: Blob }>({
      url: `${baseUrl}/api/v1/email/${suffix}`,
      method: "GET",
      accessToken,
    });
    response.filename = response.filename
      ? response.filename.substring(1, response.filename.length - 1)
      : "";
    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.blob);
    link.download = response.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.error(e);
    success = false;
  }
  return success;
};

export const addAllowBlockAddresses = async (
  inboxId: string,
  values: string[],
  allow: boolean,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<ControlFromAddress>({
      url: `${baseUrl}/api/v1/inbox/add-allow-block-address/${inboxId}`,
      method: "POST",
      body: { values, allow },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getUserInfo = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<UserInfo>({
      url: `${baseUrl}/api/v1/user`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const subscriptionCheckout = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{ url: string }>({
      url: `${baseUrl}/api/v1/user/checkout`,
      method: "POST",
      accessToken,
    });
    window.open(response.url);
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const sendOutboundEmail = async (
  fromAddress: string,
  toAddress: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ id: string }>({
      url: `${baseUrl}/api/v1/email/send`,
      method: "POST",
      body: {
        from_address: fromAddress,
        to_addresses: [{ address: toAddress, name: null }],
        subject: "Your First Outbound Email from BotMailRoom",
        plain_text:
          "Congrats on sending your first outbound email with BotMailRoom!",
        html: "<p>Congrats on sending your first outbound email with BotMailRoom!</p>",
        alternative_content: true,
      },
      accessToken,
    });
  } catch (error) {
    if (error instanceof RequestError && error.response.status === 429) {
      toast.error(
        "You have reached your outbound email limit based on your current plan."
      );
    } else {
      toast.error("Failed to send outbound email");
    }
  }
  return response;
};

export const getOutboundEmailMetadata = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<OutboundEmailMetadata>({
      url: `${baseUrl}/api/v1/email/outbound/metadata`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getInboundEmailMetadata = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<InboundEmailMetadata>({
      url: `${baseUrl}/api/v1/email/inbound/metadata`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};
