import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";

import {
  ControlFromAddress,
  InboxConfiguration,
  InboxConfigurationBase,
} from "@/types";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { loadAndFormatDate } from "@/utils/date";
import { useEffect } from "react";
import { Layout } from "@/components/Layout";
import { DataTable } from "@/components/DataTable";
import { CheckCircledIcon, PlusIcon } from "@radix-ui/react-icons";
import { Badge } from "@/components/ui/badge";
import { EmptyInboxState, InboxPopup } from "@/components/InboxForm";
import { LoadingView } from "@/components/Loader";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { useUserContext } from "@/contexts/UserContext";
import { ClickToCopy } from "@/components/ClickToCopy";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { toast } from "sonner";

const BooleanDisplay = ({ value }: { value: boolean }) => {
  return value ? <CheckCircledIcon className="text-green-500" /> : null;
};

const AddressesDisplay = ({ value }: { value: ControlFromAddress }) => {
  const allAddresses = value.domains.concat(value.addresses);
  if (allAddresses.length === 0) {
    return null;
  }
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge>{allAddresses.length}</Badge>
      </TooltipTrigger>
      <TooltipContent>
        <div className="flex flex-col gap-2">
          {allAddresses.map((address: string) => (
            <div key={address}>{address}</div>
          ))}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

const MobileInboxDisplay = (props: {
  inboxData: InboxConfiguration[];
  setActiveInbox: (inbox: InboxConfigurationBase) => void;
}) => {
  return (
    <div>
      {props.inboxData.map((inbox) => (
        <div
          key={inbox.id}
          className="border-b border-gray-200 p-4 cursor-pointer"
          onClick={() => props.setActiveInbox(inbox)}
        >
          <div className="text-sm font-bold truncate text-ellipsis w-full">
            {inbox.name}
          </div>
          <div className="text-xs text-gray-500 truncate text-ellipsis w-full">
            {inbox.email_address}
          </div>
        </div>
      ))}
    </div>
  );
};

const InboxDisplay = (props: {
  inboxData: InboxConfiguration[];
  setActiveInbox: (inbox: InboxConfigurationBase) => void;
}) => {
  const columns: ColumnDef<InboxConfiguration>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }: any) => {
        return (
          <Tooltip>
            <TooltipTrigger>
              <ClickToCopy
                text={row.original.id}
                className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
              />
            </TooltipTrigger>
            <TooltipContent>{row.original.id}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "updated",
      header: "Last Updated",
      cell: ({ row }: any) => (
        <div>{loadAndFormatDate(row.original.updated_at)}</div>
      ),
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }: any) => <div>{row.original.name}</div>,
    },
    {
      accessorKey: "email_address",
      header: "Email Address",
      cell: ({ row }: any) => (
        <ClickToCopy
          text={row.original.email_address}
          className="lowercase max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
        />
      ),
    },
    {
      accessorKey: "spf_pass_required",
      header: "SPF",
      cell: ({ row }: any) => {
        const value = row.original.spf_pass_required;
        return <BooleanDisplay value={value} />;
      },
    },
    {
      accessorKey: "dkim_pass_required    ",
      header: "DKIM",
      cell: ({ row }: any) => {
        const value = row.original.dkim_pass_required;
        return <BooleanDisplay value={value} />;
      },
    },
    {
      accessorKey: "dmarc_pass_required",
      header: "DMARC",
      cell: ({ row }: any) => {
        const value = row.original.dmarc_pass_required;
        return <BooleanDisplay value={value} />;
      },
    },
    {
      accessorKey: "allowed_from_addresses",
      header: "Allowed",
      cell: ({ row }: any) => {
        const value = row.original.allowed_from_addresses;
        return value ? <AddressesDisplay value={value} /> : null;
      },
    },
    {
      accessorKey: "blocked_from_addresses",
      header: "Blocked",
      cell: ({ row }: any) => {
        const value = row.original.blocked_from_addresses;
        return value ? <AddressesDisplay value={value} /> : null;
      },
    },
    {
      accessorKey: "webhook_url",
      header: "Webhook URL",
      cell: ({ row }: any) => {
        const value = row.original.webhook_url;
        return value ? (
          <Tooltip>
            <TooltipTrigger>
              <div className="max-w-[100px] truncate text-ellipsis">
                {value}
              </div>
            </TooltipTrigger>
            <TooltipContent>{value}</TooltipContent>
          </Tooltip>
        ) : null;
      },
    },
    {
      id: "view",
      cell: ({ row }: any) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => props.setActiveInbox(row.original)}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                props.setActiveInbox({
                  ...row.original,
                  id: null,
                  email_address: "",
                  name: `${row.original.name} (Copy)`,
                });
              }}
            >
              Duplicate
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify({
                    id: row.original.id,
                    name: row.original.name,
                    email_address: row.original.email_address,
                    allow_cc: row.original.allow_cc,
                    allow_bcc: row.original.allow_bcc,
                    spf_pass_required: row.original.spf_pass_required,
                    dkim_pass_required: row.original.dkim_pass_required,
                    dmarc_pass_required: row.original.dmarc_pass_required,
                    allowed_from_addresses: row.original.allowed_from_addresses,
                    blocked_from_addresses: row.original.blocked_from_addresses,
                    owner_user_id: row.original.owner_user_id,
                    webhook_url: row.original.webhook_url,
                    webhook_authentication_type:
                      row.original.webhook_authentication_type,
                    open_and_click_tracking:
                      row.original.open_and_click_tracking,
                  })
                );
                toast.success("Copied JSON config to clipboard");
              }}
            >
              Copy JSON Config
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];
  return <DataTable data={props.inboxData} columns={columns} />;
};

export const InboxView = () => {
  const isMobile = useIsMobile();
  const { inboxes, inboxLoading } = useUserContext();
  const [activeInbox, setActiveInbox] = React.useState<
    InboxConfigurationBase | undefined
  >(undefined);
  const [editInboxDialogOpen, setEditInboxDialogOpen] =
    React.useState<boolean>(false);
  const { userInfo } = useUserContext();

  useEffect(() => {
    if (activeInbox) {
      setEditInboxDialogOpen(true);
    }
  }, [activeInbox]);

  const userAllowedToCreateInbox =
    userInfo?.inbox_count_rate_limit === null ||
    (inboxes?.length ?? 0) < (userInfo?.inbox_count_rate_limit ?? 0);

  return (
    <Layout title="Inboxes">
      <InboxPopup
        existingConfiguration={activeInbox}
        open={editInboxDialogOpen}
        setOpen={(open) => {
          if (!open) {
            setActiveInbox(undefined);
          }
          setEditInboxDialogOpen(open);
        }}
      />
      {inboxLoading || inboxes === null ? (
        <LoadingView text="Loading inboxes..." />
      ) : inboxes.length > 0 ? (
        <div className="space-y-2">
          <div className="flex items-center justify-end space-x-2">
            <Tooltip>
              <TooltipTrigger>
                <Button
                  onClick={() =>
                    setActiveInbox({
                      id: null,
                      name: "",
                      email_address: "",
                      allow_cc: true,
                      allow_bcc: true,
                      spf_pass_required: true,
                      dkim_pass_required: true,
                      dmarc_pass_required: true,
                      allowed_from_addresses: null,
                      blocked_from_addresses: null,
                      webhook_url: null,
                      webhook_secret_preview: null,
                      webhook_authentication_type: null,
                      open_and_click_tracking: false,
                    })
                  }
                  disabled={!userAllowedToCreateInbox}
                >
                  <PlusIcon className="h-4 w-4 mr-2" />
                  Add Inbox
                </Button>
              </TooltipTrigger>
              {!userAllowedToCreateInbox && (
                <TooltipContent>
                  You have reached the inbox limit for your plan. Please upgrade
                  to add more inboxes.
                </TooltipContent>
              )}
            </Tooltip>
          </div>
          {isMobile ? (
            <MobileInboxDisplay
              inboxData={inboxes}
              setActiveInbox={setActiveInbox}
            />
          ) : (
            <InboxDisplay inboxData={inboxes} setActiveInbox={setActiveInbox} />
          )}
        </div>
      ) : (
        <EmptyInboxState />
      )}
    </Layout>
  );
};
