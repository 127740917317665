import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { CopyIcon } from "@radix-ui/react-icons";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import {
  Drawer,
  DrawerContent,
  DrawerTitle,
  DrawerDescription,
  DrawerHeader,
  DrawerFooter,
} from "@/components/ui/drawer";
import { useIsMobile } from "@/hooks/use-mobile";

export const formatSecretPreview = (secret: string) => {
  return "*".repeat(secret.length - 6) + secret.slice(-6);
};

const SecretDialog = (props: {
  secret: string | null;
  setSecret: (secret: string | null) => void;
  secretName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <AlertDialog
      open={props.open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          props.setSecret(null);
        }
        props.setOpen(isOpen);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Copy your {props.secretName}</AlertDialogTitle>
          <AlertDialogDescription>
            Make sure to copy your {props.secretName} now, as it will not be
            shown again.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="flex items-center max-w-[450px]">
          <div className="w-full truncate text-ellipsis">{props.secret}</div>
          <Button
            variant="outline"
            size="icon"
            onClick={() => {
              navigator.clipboard.writeText(props.secret!);
              toast.success("Copied to clipboard");
            }}
          >
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>
        <AlertDialogFooter>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
          >
            I've Copied It
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const SecretDrawer = (props: {
  secret: string | null;
  setSecret: (secret: string | null) => void;
  secretName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Drawer
      open={props.open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          props.setSecret(null);
        }
        props.setOpen(isOpen);
      }}
    >
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Copy your {props.secretName}</DrawerTitle>
          <DrawerDescription>
            Make sure to copy your {props.secretName} now, as it will not be
            shown again.
          </DrawerDescription>
        </DrawerHeader>
        <div className="p-4 space-y-5 overflow-y-auto">
          <div className="flex items-center space-x-2">
            <div className="w-full truncate text-ellipsis">{props.secret}</div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => {
                navigator.clipboard.writeText(props.secret!);
                toast.success("Copied to clipboard");
              }}
            >
              <CopyIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
        <DrawerFooter>
          <Button onClick={() => props.setOpen(false)}>I've Copied It</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export const SecretPopup = (props: {
  secret: string | null;
  setSecret: (secret: string | null) => void;
  secretName: string;
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (props.secret) {
      setOpen(true);
    }
  }, [props.secret]);

  return isMobile ? (
    <SecretDrawer
      open={open}
      setOpen={setOpen}
      secret={props.secret}
      setSecret={props.setSecret}
      secretName={props.secretName}
    />
  ) : (
    <SecretDialog
      open={open}
      setOpen={setOpen}
      secret={props.secret}
      setSecret={props.setSecret}
      secretName={props.secretName}
    />
  );
};
