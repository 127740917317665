import { Button } from "@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const RefreshDataButton = (props: {
  loadEmailData: () => void;
  emailDataLoading: boolean;
}) => (
  <Tooltip>
    <TooltipTrigger>
      <Button
        className="default"
        size="icon"
        onClick={props.loadEmailData}
        disabled={props.emailDataLoading}
      >
        <ReloadIcon
          className={cn("w-4 h-4", props.emailDataLoading && "animate-spin")}
        />
      </Button>
    </TooltipTrigger>
    <TooltipContent>Refresh Emails</TooltipContent>
  </Tooltip>
);
