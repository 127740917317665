import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenu,
  SidebarRail,
  SidebarGroupLabel,
  SidebarGroupContent,
} from "@/components/ui/sidebar";
import { UserNav } from "@/components/UserNav";
import { useUserContext } from "@/contexts/UserContext";
import { cn } from "@/lib/utils";
import {
  EnvelopeOpenIcon,
  Link1Icon,
  PaperPlaneIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import { ArrowUp, Code, Inbox } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useAuthInfo, useHostedPageUrls } from "@propelauth/react";
import { subscriptionCheckout } from "@/utils/apiCalls";

const SidebarItem = (props: {
  icon: React.ReactNode;
  label: string;
  href: string;
}) => {
  const { pathname } = useLocation();
  return (
    <SidebarMenuItem key={props.label}>
      <SidebarMenuButton asChild tooltip={props.label}>
        <a
          href={props.href}
          className={cn(
            "flex items-center gap-2",
            pathname === props.href && "bg-primary"
          )}
        >
          {props.icon}
          <span>{props.label}</span>
        </a>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

export const PageSidebar = () => {
  const authInfo = useAuthInfo();
  const { hasApiToken, userInfo } = useUserContext();
  const { getAccountPageUrl } = useHostedPageUrls();
  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton>
              <img src="/logo.png" alt="Logo" className="h-auto w-10" />
              <span className="text-lg font-semibold">BotMailRoom</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Pages</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarItem
                icon={<EnvelopeOpenIcon />}
                label="Emails"
                href="/"
              />
              <SidebarItem
                icon={<PaperPlaneIcon />}
                label="Outbound Emails"
                href="/outbound"
              />
              <SidebarItem icon={<Inbox />} label="Inboxes" href="/inbox" />
              <SidebarItem
                icon={<Link1Icon />}
                label="Domains"
                href="/domain"
              />
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        {!hasApiToken && (
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                tooltip="Create Your First Api Key"
                className="bg-primary"
              >
                <a href={`${getAccountPageUrl()}/api_keys`} target="_blank">
                  <PlusIcon className="w-4 h-4" />
                  <span>Create Your First Api Key</span>
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        {userInfo?.plan === "free" && (
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                className="bg-primary"
                tooltip="Upgrade to Pro"
                onClick={() =>
                  subscriptionCheckout(authInfo.accessToken ?? null)
                }
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <ArrowUp className="w-4 h-4" />
                  <span>Upgrade to Pro</span>
                </div>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild tooltip="API Docs">
              <a href={`https://docs.botmailroom.com`} target="_blank">
                <Code className="w-4 h-4" />
                <span>API Docs</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
        <UserNav />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};
