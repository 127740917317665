import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useIsMobile } from "@/hooks/use-mobile";

import { EmailAddress, EmailOverview, InboundEmailMetadata } from "@/types";
import {
  EmailStatusBadge,
  WebhookStatusBadge,
} from "@/components/StatusBadges";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { loadAndFormatDate } from "@/utils/date";
import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import {
  addAllowBlockAddresses,
  getEmailData,
  getInboundEmailMetadata,
  markEmail,
} from "@/utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "@/components/Layout";
import { DataTable } from "@/components/DataTable";
import { LoadingView } from "@/components/Loader";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { SearchFilter, SelectFilter } from "@/components/SelectFilter";
import { Button } from "@/components/ui/button";
import { EmptyInboxState } from "@/components/InboxForm";
import { MailIcon, X } from "lucide-react";
import { CheckIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { EmailDisplay } from "@/components/EmailDisplay";
import { useUserContext } from "@/contexts/UserContext";
import { ClickToCopy } from "@/components/ClickToCopy";
import { RefreshDataButton } from "@/components/RefreshDataButton";

const EmptyMailState = (props: { emailAddress: string }) => {
  const onEmailClick = (emailAddress: string) => {
    // Encode all parameters to handle special characters
    const params = new URLSearchParams({
      subject: "Your First Email",
      body: "Congrats on sending your first email to BotMailRoom!",
      view: "cm",
    }).toString();

    // Create mailto URL
    const mailtoUrl = `mailto:${encodeURIComponent(emailAddress)}?${params}`;

    window.open(mailtoUrl, "_blank");

    // reload the page after 15 seconds
    setTimeout(() => {
      window.location.reload();
    }, 15000);
  };

  return (
    <div className="flex justify-center">
      <div className="space-y-1">
        <div className="text-muted-foreground text-sm text-center">
          No emails found
        </div>
        <Button
          variant="default"
          onClick={() => onEmailClick(props.emailAddress)}
        >
          <MailIcon className="w-4 h-4 mr-2" />
          Send Email to {props.emailAddress}
        </Button>
      </div>
    </div>
  );
};

const EmptyEmailDisplay = () => {
  const { inboxes, inboxLoading } = useUserContext();
  return inboxLoading ? (
    <LoadingView text="Loading emails..." />
  ) : inboxes && inboxes.length > 0 ? (
    <EmptyMailState emailAddress={inboxes[0].email_address} />
  ) : (
    <EmptyInboxState />
  );
};

const MobileEmailsDisplay = (props: {
  emailData: EmailOverview[];
  setActiveEmailId: (emailId: string | null) => void;
}) => {
  return (
    <div>
      {props.emailData.map((email) => (
        <div
          key={email.id}
          className="border-b border-gray-200 p-4"
          onClick={() => {
            if (email.status === "valid" || email.user_mark_status) {
              props.setActiveEmailId(email.id);
            }
          }}
        >
          <div className="text-sm font-bold truncate text-ellipsis w-full">
            {email.subject}
          </div>
          <div className="flex items-center space-x-1">
            <EmailStatusBadge
              status={email.user_mark_status ? "valid" : email.status}
              status_message={email.status_message}
            />
            <div className="text-xs text-gray-500 truncate text-ellipsis w-full">
              {email.from_address.address}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const emailCurrentStatus = (row: EmailOverview) => {
  const baseStatusValid: boolean = row.status === "valid";
  const userMarkStatus: boolean = row.user_mark_status;
  return (
    (baseStatusValid && userMarkStatus === false) ||
    (!baseStatusValid && userMarkStatus === true)
  );
};

const EmailsDisplay = (props: {
  emailData: EmailOverview[];
  setEmailData: React.Dispatch<React.SetStateAction<EmailOverview[] | null>>;
  loadEmailData: () => void;
  emailDataLoading: boolean;
  inboundEmailMetadata: InboundEmailMetadata | null;
  validOnly: boolean;
  setValidOnly: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  inboxFilter: string[];
  setInboxFilter: React.Dispatch<React.SetStateAction<string[]>>;
  fromAddressFilter: string[];
  setFromAddressFilter: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const authInfo = useAuthInfo();
  const isMobile = useIsMobile();
  const { inboxes } = useUserContext();
  const [activeEmailId, setActiveEmailId] = React.useState<string | null>(null);

  const columns: ColumnDef<EmailOverview>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }: any) => {
        return (
          <Tooltip>
            <TooltipTrigger>
              <ClickToCopy
                text={row.original.id}
                className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
              />
            </TooltipTrigger>
            <TooltipContent>{row.original.id}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: ({ row }: any) => (
        <div>{loadAndFormatDate(row.getValue("date"))}</div>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }: any) => {
        const statusValid = emailCurrentStatus(row.original);
        const [markingEmail, setMarkingEmail] = React.useState(false);
        const handleMarkEmail = async (emailId: string) => {
          setMarkingEmail(true);
          const updatedEmailOverview = await markEmail(
            emailId,
            !statusValid,
            authInfo.accessToken ?? null
          );
          if (updatedEmailOverview !== null) {
            props.setEmailData((prev) => {
              if (prev === null) {
                return null;
              }
              return prev.map((email) =>
                email.id === emailId ? updatedEmailOverview : email
              );
            });
          } else {
            toast.error(
              `Failed to mark email as ${!statusValid ? "invalid" : "valid"}`
            );
          }
          setMarkingEmail(false);
        };

        return (
          <div className="flex items-center space-x-1">
            <EmailStatusBadge
              status={row.original.status}
              status_message={row.original.status_message}
            />
            {row.original.user_mark_status && (
              <Badge
                className={cn(
                  "text-white cursor-default",
                  statusValid
                    ? "bg-green-500 hover:bg-green-500"
                    : "bg-red-500 hover:bg-red-500"
                )}
              >
                marked {statusValid ? "valid" : "invalid"}
              </Badge>
            )}
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="secondary"
                  size="icon"
                  className="h-6 w-6"
                  onClick={() => handleMarkEmail(row.original.id)}
                  disabled={markingEmail}
                >
                  {markingEmail ? (
                    <ReloadIcon className="w-4 h-4 animate-spin" />
                  ) : statusValid ? (
                    <X className="w-4 h-4" />
                  ) : (
                    <CheckIcon className="w-4 h-4" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Mark Email as {statusValid ? "Invalid" : "Valid"}
              </TooltipContent>
            </Tooltip>
          </div>
        );
      },
    },
    {
      accessorKey: "from_address",
      header: "From",
      cell: ({ row }: any) => {
        const fromAddress = row.original.from_address.address;
        const inbox = inboxes?.find(
          (inbox) => inbox.id === row.original.inbox_id
        );
        const statusValid = emailCurrentStatus(row.original);
        const allow: boolean = !statusValid;
        const controlFromAddress = (allow
          ? inbox?.allowed_from_addresses
          : inbox?.blocked_from_addresses) ?? { addresses: [], domains: [] };

        // check if from address is in controlFromAddress.addresses or controlFromAddress.domains
        const alreadyHandled =
          controlFromAddress.addresses.includes(fromAddress) ||
          controlFromAddress.domains.some((domain) =>
            fromAddress.endsWith(`@${domain}`)
          );

        const [loading, setLoading] = React.useState(false);
        const handleAddAllowBlockAddresses = async () => {
          setLoading(true);
          const response = await addAllowBlockAddresses(
            inbox?.id ?? "",
            [fromAddress],
            allow,
            authInfo.accessToken ?? null
          );
          if (response !== null) {
            toast.success(
              `Added email to ${allow ? "Allowed" : "Blocked"} List`
            );
          } else {
            toast.error(
              `Failed to add email to ${allow ? "Allowed" : "Blocked"} List`
            );
          }
          setLoading(false);
        };
        return (
          <div className="flex items-center space-x-1">
            <Tooltip>
              <TooltipTrigger>
                <ClickToCopy
                  text={fromAddress}
                  className="lowercase max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
                />
              </TooltipTrigger>
              <TooltipContent>{fromAddress}</TooltipContent>
            </Tooltip>
            {!alreadyHandled && (
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="secondary"
                    size="icon"
                    className="h-6 w-6"
                    onClick={handleAddAllowBlockAddresses}
                    disabled={loading}
                  >
                    {loading ? (
                      <ReloadIcon className="w-4 h-4 animate-spin" />
                    ) : allow ? (
                      <CheckIcon className="w-4 h-4" />
                    ) : (
                      <X className="w-4 h-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Add email to {allow ? "Allowed" : "Blocked"} List
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "to_addresses",
      header: "To",
      cell: ({ row }: any) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.to_addresses[0].address}
              className="lowercase max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex flex-col gap-2">
              {row.original.to_addresses.map((address: EmailAddress) => (
                <div key={address.address}>{address.address}</div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "inbox",
      header: "Inbox",
      cell: ({ row }: any) => {
        const inbox = inboxes?.find(
          (inbox) => inbox.id === row.original.inbox_id
        );
        return (
          <Tooltip>
            <TooltipTrigger>
              <a href="/inbox">
                <div className="max-w-[100px] truncate text-ellipsis">
                  {inbox?.name ?? ""}
                </div>
              </a>
            </TooltipTrigger>
            <TooltipContent>Go To {inbox?.name ?? ""}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "subject",
      header: "Subject",
      cell: ({ row }: any) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.subject}
              className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>{row.original.subject}</TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "latest_webhook_log",
      header: "Webhook Status",
      cell: ({ row }: any) => {
        const webhookLog = row.original.latest_webhook_log;
        const inbox = inboxes?.find(
          (inbox) => inbox.id === row.original.inbox_id
        );
        return (
          <WebhookStatusBadge
            emailId={row.original.id}
            webhookUrl={inbox?.webhook_url ?? null}
            statusCode={webhookLog?.status_code ?? null}
            createdAt={webhookLog?.created_at ?? null}
          />
        );
      },
    },
    {
      id: "view",
      cell: ({ row }: any) => {
        const clickable =
          row.original.status === "valid" || row.original.user_mark_status;
        return clickable ? (
          <Button
            variant="secondary"
            size="sm"
            className="text-xs h-6"
            onClick={() => {
              setActiveEmailId(row.original.id);
            }}
          >
            View
          </Button>
        ) : null;
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-end space-x-2">
        <SearchFilter
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          placeholder="Search..."
          className={cn(isMobile ? "w-full" : "w-[200px]")}
        />
        <Label htmlFor="valid-only">Valid Only</Label>
        <Switch
          id="valid-only"
          checked={props.validOnly}
          onCheckedChange={(checked) => {
            props.setValidOnly(checked);
          }}
        />
        {!isMobile && (
          <>
            <SelectFilter
              title="Inbox"
              filterCounts={props.inboundEmailMetadata?.inbox_count ?? {}}
              activeFilter={props.inboxFilter}
              setActiveFilter={props.setInboxFilter}
              nameFormatter={(id) => {
                const inbox = inboxes?.find((inbox) => inbox.id === id);
                return inbox?.name ?? "";
              }}
            />
            <SelectFilter
              title="From Address"
              filterCounts={
                props.inboundEmailMetadata?.from_address_count ?? {}
              }
              activeFilter={props.fromAddressFilter}
              setActiveFilter={props.setFromAddressFilter}
            />
            <RefreshDataButton
              loadEmailData={props.loadEmailData}
              emailDataLoading={props.emailDataLoading}
            />
          </>
        )}
      </div>
      {isMobile ? (
        <MobileEmailsDisplay
          emailData={props.emailData}
          setActiveEmailId={setActiveEmailId}
        />
      ) : (
        <DataTable data={props.emailData} columns={columns} />
      )}
      <EmailDisplay
        emailId={activeEmailId}
        setEmailId={setActiveEmailId}
        outbound={false}
      />
    </>
  );
};

export const EmailView = () => {
  const authInfo = useAuthInfo();
  const [emailData, setEmailData] = React.useState<EmailOverview[] | null>(
    null
  );
  const [emailDataLoading, setEmailDataLoading] = React.useState(true);
  const [inboundEmailMetadata, setInboundEmailMetadata] =
    React.useState<InboundEmailMetadata | null>(null);
  const [inboxFilter, setInboxFilter] = React.useState<string[]>([]);
  const [fromAddressFilter, setFromAddressFilter] = React.useState<string[]>(
    []
  );
  const [validOnly, setValidOnly] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const loadEmailData = async () => {
    setEmailDataLoading(true);
    const response = await getEmailData(
      validOnly,
      inboxFilter,
      fromAddressFilter,
      searchTerm,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setEmailData(response);
    } else {
      toast.error("Failed to fetch email data");
    }
    setEmailDataLoading(false);
  };

  const loadEmailMetadata = async () => {
    const response = await getInboundEmailMetadata(
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setInboundEmailMetadata(response);
    } else {
      toast.error("Failed to fetch email metadata, please try again later");
    }
  };

  const loadAllData = () => {
    loadEmailMetadata();
    loadEmailData();
  };

  useEffect(() => {
    loadAllData();
  }, []);

  useEffect(() => {
    loadEmailData();
  }, [validOnly, inboxFilter, fromAddressFilter, searchTerm]);

  return (
    <Layout title="Emails">
      {emailDataLoading || emailData === null ? (
        <LoadingView text="Loading emails..." />
      ) : inboundEmailMetadata?.inbox_count &&
        Object.keys(inboundEmailMetadata.inbox_count).length === 0 ? (
        <EmptyEmailDisplay />
      ) : (
        <EmailsDisplay
          emailData={emailData}
          setEmailData={setEmailData}
          loadEmailData={loadEmailData}
          emailDataLoading={emailDataLoading}
          inboundEmailMetadata={inboundEmailMetadata}
          validOnly={validOnly}
          setValidOnly={setValidOnly}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          inboxFilter={inboxFilter}
          setInboxFilter={setInboxFilter}
          fromAddressFilter={fromAddressFilter}
          setFromAddressFilter={setFromAddressFilter}
        />
      )}
    </Layout>
  );
};
