import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useIsMobile } from "@/hooks/use-mobile";

import {
  EmailAddress,
  EmailOverview,
  OutboundEmailMetadata,
  OutboundEmailOverview,
} from "@/types";
import { OutgoingEmailStatusBadge } from "@/components/StatusBadges";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { loadAndFormatDate } from "@/utils/date";
import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import {
  getOutboundEmailData,
  getOutboundEmailMetadata,
  sendOutboundEmail,
} from "@/utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "@/components/Layout";
import { DataTable } from "@/components/DataTable";
import { LoadingView } from "@/components/Loader";
import { SearchFilter, SelectFilter } from "@/components/SelectFilter";
import { Button } from "@/components/ui/button";
import { EmptyInboxState } from "@/components/InboxForm";
import { PaperPlaneIcon, ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { EmailDisplay } from "@/components/EmailDisplay";
import { useUserContext } from "@/contexts/UserContext";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { ClickToCopy } from "@/components/ClickToCopy";
import { RefreshDataButton } from "@/components/RefreshDataButton";

const EmptyOutboundMailState = (props: { fromAddress: string }) => {
  const authInfo = useAuthInfo();
  const [sending, setSending] = React.useState(false);
  const [toAddress, setToAddress] = React.useState("");

  const onSendEmailClick = async () => {
    if (toAddress === "") {
      toast.error("Please enter an email address to send to");
      return;
    }
    setSending(true);
    const response = await sendOutboundEmail(
      props.fromAddress,
      toAddress,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      toast.success("Email sent successfully");
      window.location.reload();
    }
    setSending(false);
  };

  return (
    <div className="flex justify-center">
      <div className="space-y-1">
        <div className="text-muted-foreground text-sm text-center">
          No outbound emails found
        </div>
        <Input
          value={toAddress}
          onChange={(e) => setToAddress(e.target.value)}
        />
        <Button
          variant="default"
          onClick={() => onSendEmailClick()}
          disabled={toAddress === "" || sending}
        >
          {sending ? (
            <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
          ) : (
            <PaperPlaneIcon className="w-4 h-4 mr-2" />
          )}
          Send Email from {props.fromAddress} to {toAddress}
        </Button>
      </div>
    </div>
  );
};

const EmptyEmailDisplay = () => {
  const { inboxes, inboxLoading } = useUserContext();

  return inboxLoading ? (
    <LoadingView text="Loading emails..." />
  ) : inboxes && inboxes.length > 0 ? (
    <EmptyOutboundMailState fromAddress={inboxes[0].email_address} />
  ) : (
    <EmptyInboxState />
  );
};

const MobileEmailsDisplay = (props: {
  emailData: OutboundEmailOverview[];
  setActiveEmailId: (emailId: string | null) => void;
}) => {
  return (
    <div>
      {props.emailData.map((email) => (
        <div
          key={email.id}
          className="border-b border-gray-200 p-4"
          onClick={() => {
            props.setActiveEmailId(email.id);
          }}
        >
          <div className="text-sm font-bold truncate text-ellipsis w-full">
            {email.subject}
          </div>
          <div className="flex items-center space-x-1">
            <OutgoingEmailStatusBadge status={email.status} />
            <div className="text-xs text-gray-500 truncate text-ellipsis w-full">
              {email.from_address.address}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const EmailsDisplay = (props: {
  emailData: OutboundEmailOverview[];
  setEmailData: React.Dispatch<
    React.SetStateAction<OutboundEmailOverview[] | null>
  >;
  loadAllData: () => void;
  emailDataLoading: boolean;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setInboxFilter: React.Dispatch<React.SetStateAction<string[]>>;
  inboxFilter: string[];
  searchTerm: string;
  emailMetadata: OutboundEmailMetadata | null;
}) => {
  const isMobile = useIsMobile();
  const { inboxes } = useUserContext();
  const [activeEmailId, setActiveEmailId] = React.useState<string | null>(null);

  const columns: ColumnDef<EmailOverview>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }: any) => {
        return (
          <Tooltip>
            <TooltipTrigger>
              <ClickToCopy
                text={row.original.id}
                className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
              />
            </TooltipTrigger>
            <TooltipContent>{row.original.id}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: ({ row }: any) => (
        <div>{loadAndFormatDate(row.getValue("date"))}</div>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }: any) => {
        return <OutgoingEmailStatusBadge status={row.original.status} />;
      },
    },
    {
      accessorKey: "from_address",
      header: "From",
      cell: ({ row }: any) => {
        const fromAddress = row.original.from_address.address;
        return (
          <Tooltip>
            <TooltipTrigger>
              <ClickToCopy
                text={fromAddress}
                className="lowercase max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
              />
            </TooltipTrigger>
            <TooltipContent>{fromAddress}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "to_addresses",
      header: "To",
      cell: ({ row }: any) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.to_addresses[0].address}
              className="lowercase max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex flex-col gap-2">
              {row.original.to_addresses.map((address: EmailAddress) => (
                <div key={address.address}>{address.address}</div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "inbox",
      header: "Inbox",
      cell: ({ row }: any) => {
        const inbox = inboxes?.find(
          (inbox) => inbox.id === row.original.inbox_id
        );
        return (
          <Tooltip>
            <TooltipTrigger>
              <a href="/inbox">
                <div className="max-w-[100px] truncate text-ellipsis">
                  {inbox?.name ?? ""}
                </div>
              </a>
            </TooltipTrigger>
            <TooltipContent>Go To {inbox?.name ?? ""}</TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "subject",
      header: "Subject",
      cell: ({ row }: any) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.subject}
              className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>{row.original.subject}</TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "latest_event",
      header: "Latest Event",
      cell: ({ row }: any) => {
        if (row.original.latest_event === null) {
          return null;
        }
        return (
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="default">
                {row.original.latest_event.event_type}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              {loadAndFormatDate(row.original.latest_event.date)}
            </TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      id: "view",
      cell: ({ row }: any) => {
        return (
          <Button
            variant="secondary"
            size="sm"
            className="text-xs h-6"
            onClick={() => {
              setActiveEmailId(row.original.id);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center justify-end space-x-2">
        <SearchFilter
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          placeholder="Search..."
          className={cn(isMobile ? "w-full" : "w-[200px]")}
        />
        {!isMobile && props.emailMetadata !== null && (
          <>
            <SelectFilter
              title="Inbox"
              filterCounts={props.emailMetadata.inbox_count}
              activeFilter={props.inboxFilter}
              setActiveFilter={props.setInboxFilter}
              nameFormatter={(id) => {
                const inbox = inboxes?.find((inbox) => inbox.id === id);
                return inbox?.name ?? "";
              }}
            />
          </>
        )}
        <RefreshDataButton
          loadEmailData={props.loadAllData}
          emailDataLoading={props.emailDataLoading}
        />
      </div>
      {isMobile ? (
        <MobileEmailsDisplay
          emailData={props.emailData}
          setActiveEmailId={setActiveEmailId}
        />
      ) : (
        <DataTable data={props.emailData} columns={columns} />
      )}
      <EmailDisplay
        emailId={activeEmailId}
        setEmailId={setActiveEmailId}
        outbound={true}
      />
    </>
  );
};

export const OutboundEmailView = () => {
  const authInfo = useAuthInfo();
  const [emailData, setEmailData] = React.useState<
    OutboundEmailOverview[] | null
  >(null);
  const [emailDataLoading, setEmailDataLoading] = React.useState(true);
  const [inboxFilter, setInboxFilter] = React.useState<string[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [emailMetadata, setEmailMetadata] =
    React.useState<OutboundEmailMetadata | null>(null);

  const loadEmailData = async () => {
    setEmailDataLoading(true);
    const response = await getOutboundEmailData(
      inboxFilter,
      searchTerm || null,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setEmailData(response);
    } else {
      toast.error("Failed to fetch outbound email data");
    }
    setEmailDataLoading(false);
  };

  const loadEmailMetadata = async () => {
    const response = await getOutboundEmailMetadata(
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setEmailMetadata(response);
    } else {
      toast.error("Failed to fetch outbound email metadata, please try again");
    }
  };

  const loadAllData = () => {
    loadEmailMetadata();
    loadEmailData();
  };

  useEffect(() => {
    loadAllData();
  }, []);

  useEffect(() => {
    loadEmailData();
  }, [inboxFilter, searchTerm]);

  return (
    <Layout title="Outbound Emails">
      {emailDataLoading || emailData === null ? (
        <LoadingView text="Loading outbound emails..." />
      ) : emailMetadata?.inbox_count &&
        Object.keys(emailMetadata.inbox_count).length === 0 ? (
        <EmptyEmailDisplay />
      ) : (
        <EmailsDisplay
          emailData={emailData}
          setEmailData={setEmailData}
          loadAllData={loadAllData}
          emailDataLoading={emailDataLoading}
          setSearchTerm={setSearchTerm}
          setInboxFilter={setInboxFilter}
          inboxFilter={inboxFilter}
          searchTerm={searchTerm}
          emailMetadata={emailMetadata}
        />
      )}
    </Layout>
  );
};
