import { DataTable } from "@/components/DataTable";
import { Layout } from "@/components/Layout";
import { LoadingView } from "@/components/Loader";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { DNSRecordStatus, Domain, DomainDNSRecord } from "@/types";
import { getDomains, verifyDomain } from "@/utils/apiCalls";
import { loadAndFormatDate } from "@/utils/date";

import { useAuthInfo } from "@propelauth/react";
import { CheckIcon, ReloadIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  AddDomainPopup,
  DomainForm,
  DomainRecordsPopup,
} from "@/components/DomainForm";
import { useIsMobile } from "@/hooks/use-mobile";
import { useUserContext } from "@/contexts/UserContext";

const EmptyDomainState = (props: {
  successCallback: (domain: Domain) => void;
}) => {
  const { userInfo } = useUserContext();
  const userAllowedToCreateDomain =
    userInfo &&
    (userInfo?.custom_domain_rate_limit === null ||
      userInfo?.custom_domain_rate_limit > 0);

  return (
    <div className="flex justify-center">
      <Card className="w-[600px]">
        <CardHeader>
          <CardTitle>Add a Custom Domain</CardTitle>
          <CardDescription>
            Add your custom domain to start receiving emails directed towards
            it. You need to control the DNS records for this domain. You do not
            need to create a custom domain to use BotMailRoom,
            `inbox.botmailroom.com` is available to use.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {userAllowedToCreateDomain ? (
            <DomainForm onSubmitSuccessCallback={props.successCallback} />
          ) : (
            <div className="text-sm text-red-500">
              You are on the free tier and so can not create any custom domains.
              You can upgrade to the paid tier to create unlimited custom
              domains or use `inbox.botmailroom.com`.
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const DomainStatusBadge = (props: {
  status: DNSRecordStatus;
  records: DomainDNSRecord[];
}) => {
  let badgeClass;
  let displayStatus;
  if (props.status === DNSRecordStatus.success) {
    badgeClass = "bg-green-500 hover:bg-green-500";
    displayStatus = "success";
  } else if (props.status === DNSRecordStatus.failed) {
    badgeClass = "bg-red-500 hover:bg-red-500";
    displayStatus = "failed";
  } else if (props.status === DNSRecordStatus.not_started) {
    badgeClass = "bg-gray-500 hover:bg-gray-500";
    displayStatus = "not started";
  } else if (props.status === DNSRecordStatus.optional) {
    badgeClass = "bg-blue-500 hover:bg-blue-500";
    displayStatus = "optional";
  } else {
    badgeClass = "bg-yellow-500 hover:bg-yellow-500";
    displayStatus = "pending";
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge className={cn("cursor-default text-white", badgeClass)}>
          {props.records.length} {displayStatus}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>
        <div className="space-y-1 max-w-[600px]">
          {props.records.map((record) => (
            <div key={record.name} className="text-left">
              {record.type} {record.name}
            </div>
          ))}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

const DomainStatusBadgeDisplay = (props: {
  dnsRecords: DomainDNSRecord[];
  id: string;
  setDomainData: React.Dispatch<React.SetStateAction<Domain[] | null>>;
}) => {
  const authInfo = useAuthInfo();
  const [verifyingDomain, setVerifyingDomain] = useState<boolean>(false);
  const handleVerifyDomain = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setVerifyingDomain(true);
    const response = await verifyDomain(props.id, authInfo.accessToken ?? null);
    if (response === true) {
      toast.success("Domain verified");
      props.setDomainData((prev) => {
        if (prev === null) {
          return null;
        }
        return prev.map((domain) => {
          if (domain.id === props.id) {
            return {
              ...domain,
              dns_records: domain.dns_records.map((record) => ({
                ...record,
                status: DNSRecordStatus.success,
              })),
            };
          }
          return domain;
        });
      });
    } else {
      toast.error("Failed to verify domain");
    }
    setVerifyingDomain(false);
  };
  const dnsRecordsGroupedByStatus: Record<DNSRecordStatus, DomainDNSRecord[]> =
    props.dnsRecords.reduce(
      (
        acc: Record<DNSRecordStatus, DomainDNSRecord[]>,
        record: DomainDNSRecord
      ) => {
        const status = record.status;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(record);
        return acc;
      },
      {} as Record<DNSRecordStatus, DomainDNSRecord[]>
    );
  return (
    <div className="flex items-center space-x-2">
      {Object.entries(dnsRecordsGroupedByStatus).map(([status, records]) => (
        <DomainStatusBadge
          status={status as DNSRecordStatus}
          records={records}
        />
      ))}
      {Object.keys(dnsRecordsGroupedByStatus).some(
        (status) => status !== DNSRecordStatus.success
      ) && (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="secondary"
              size="icon"
              className="h-6 w-6"
              onClick={handleVerifyDomain}
              disabled={verifyingDomain}
            >
              {verifyingDomain ? (
                <ReloadIcon className="w-4 h-4 animate-spin" />
              ) : (
                <CheckIcon className="w-4 h-4" />
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Verify your domain to receive emails directed towards it. Make sure
            you have updated your DNS records with the values.
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

const MobileDomainDisplay = (props: {
  domainData: Domain[];
  setActiveDomain: (domain: Domain) => void;
  setDomainData: React.Dispatch<React.SetStateAction<Domain[] | null>>;
}) => {
  return (
    <div>
      {props.domainData.map((domain) => (
        <div
          key={domain.id}
          className="border-b border-gray-200 p-4"
          onClick={() => props.setActiveDomain(domain)}
        >
          <div className="text-sm font-bold truncate text-ellipsis w-full">
            {domain.domain}
          </div>
          <DomainStatusBadgeDisplay
            dnsRecords={domain.dns_records}
            id={domain.id}
            setDomainData={props.setDomainData}
          />
        </div>
      ))}
    </div>
  );
};

const DomainsDisplay = (props: {
  domainData: Domain[];
  setActiveDomain: (domain: Domain) => void;
  setDomainData: React.Dispatch<React.SetStateAction<Domain[] | null>>;
}) => {
  const columns: ColumnDef<Domain>[] = [
    {
      accessorKey: "created_at",
      header: "Created At",
      cell: ({ row }: any) => (
        <div>{loadAndFormatDate(row.original.created_at)}</div>
      ),
    },
    {
      accessorKey: "domain",
      header: "Domain",
      cell: ({ row }: any) => <div>{row.original.domain}</div>,
    },
    {
      accessorKey: "status",
      header: "Verification Status",
      cell: ({ row }: any) => {
        return (
          <DomainStatusBadgeDisplay
            dnsRecords={row.original.dns_records}
            id={row.original.id}
            setDomainData={props.setDomainData}
          />
        );
      },
    },
    {
      accessorKey: "dns_records",
      header: "DNS Records",
      cell: ({ row }: any) => (
        <Button
          variant="secondary"
          size="sm"
          className="text-xs h-6"
          onClick={() => {
            props.setActiveDomain(row.original);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return <DataTable data={props.domainData} columns={columns} />;
};

export const DomainView = () => {
  const authInfo = useAuthInfo();
  const isMobile = useIsMobile();
  const [domainData, setDomainData] = useState<Domain[] | null>(null);
  const [domainDataLoading, setDomainDataLoading] = useState(true);
  const [activeDomain, setActiveDomain] = useState<Domain | null>(null);

  const loadDomainData = async () => {
    setDomainDataLoading(true);
    const response = await getDomains(authInfo.accessToken ?? null);
    if (response !== null) {
      setDomainData(response);
    } else {
      toast.error("Failed to fetch domain data");
    }
    setDomainDataLoading(false);
  };

  useEffect(() => {
    loadDomainData();
  }, []);

  return (
    <Layout title="Domains">
      {activeDomain && (
        <DomainRecordsPopup domain={activeDomain} setDomain={setActiveDomain} />
      )}
      {domainDataLoading || domainData === null ? (
        <LoadingView text="Loading domains..." />
      ) : domainData.length === 0 ? (
        <EmptyDomainState
          successCallback={(domain) => {
            setDomainData((prev) => {
              if (prev === null) {
                return null;
              }
              return [domain, ...prev];
            });
            setActiveDomain(domain);
          }}
        />
      ) : (
        <div className="space-y-2">
          <div className="flex items-center justify-end space-x-2">
            <AddDomainPopup
              onSubmitSuccessCallback={(domain) => {
                setDomainData((prev) => {
                  if (prev === null) {
                    return null;
                  }
                  return [domain, ...prev.filter((v) => v.id !== domain.id)];
                });
                setActiveDomain(domain);
              }}
            />
          </div>
          {isMobile ? (
            <MobileDomainDisplay
              domainData={domainData}
              setActiveDomain={setActiveDomain}
              setDomainData={setDomainData}
            />
          ) : (
            <DomainsDisplay
              domainData={domainData}
              setActiveDomain={setActiveDomain}
              setDomainData={setDomainData}
            />
          )}
        </div>
      )}
    </Layout>
  );
};
