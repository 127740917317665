import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DNSRecordStatus, Domain, DomainDNSRecord } from "@/types";
import { checkDomainAvailability, createDomain } from "@/utils/apiCalls";
import { z } from "zod";

import { useAuthInfo } from "@propelauth/react";
import {
  CheckCircledIcon,
  CheckIcon,
  CopyIcon,
  CrossCircledIcon,
  InfoCircledIcon,
  PlusIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useIsMobile } from "@/hooks/use-mobile";
import {
  Drawer,
  DrawerTitle,
  DrawerHeader,
  DrawerContent,
  DrawerTrigger,
  DrawerDescription,
} from "@/components/ui/drawer";

const domainFormSchema = z.object({
  domain: z.string(),
});

export const DomainForm = (props: {
  disabled?: boolean;
  onSubmitSuccessCallback?: (domain: Domain) => void;
}) => {
  const authInfo = useAuthInfo();
  const [checkDomainLoading, setCheckDomainLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof domainFormSchema>>({
    resolver: zodResolver(domainFormSchema),
    defaultValues: {
      domain: "",
    },
    mode: "all",
  });

  const handleCheckDomainAvailability = async (
    domain: string,
    displayToast: boolean = true
  ) => {
    setCheckDomainLoading(true);
    const response = await checkDomainAvailability(
      domain,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      if (response.available) {
        if (displayToast) {
          toast.success("Domain is available");
        }
      } else {
        form.setError("domain", {
          type: "manual",
          message: "Domain is already in use",
        });
      }
    } else {
      toast.error("Unable to check domain availability");
    }
    setCheckDomainLoading(false);
    return response?.available ?? false;
  };

  const onSubmit = async (data: z.infer<typeof domainFormSchema>) => {
    setLoading(true);
    const domainAvailablePass = await handleCheckDomainAvailability(
      data.domain,
      false
    );
    if (!domainAvailablePass) {
      setLoading(false);
      return;
    }
    const response = await createDomain(
      data.domain,
      authInfo.accessToken ?? null
    );
    setLoading(false);
    if (response !== null) {
      toast.success("Domain created");
      if (props.onSubmitSuccessCallback) {
        props.onSubmitSuccessCallback(response);
      }
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="domain"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Domain</FormLabel>
              <FormControl>
                <div className="flex items-center space-x-2">
                  <Input
                    className="w-full"
                    type="text"
                    autoComplete="off"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    {...field}
                  />
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        variant="secondary"
                        size="icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          handleCheckDomainAvailability(field.value);
                        }}
                        disabled={checkDomainLoading}
                      >
                        {checkDomainLoading ? (
                          <ReloadIcon className="w-4 h-4 animate-spin" />
                        ) : (
                          <CheckIcon className="w-4 h-4" />
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      Check if the domain is available
                    </TooltipContent>
                  </Tooltip>
                </div>
              </FormControl>
              <FormDescription>
                The domain you want to use for your emails. You must control the
                DNS records for this domain.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={loading}>
          Add
          {loading && <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />}
        </Button>
      </form>
    </Form>
  );
};

const dnsColumns: ColumnDef<DomainDNSRecord>[] = [
  {
    accessorKey: "type",
    header: "Type",
    cell: ({ row }: any) => <DNSRecordDisplay value={row.original.type} />,
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }: any) => <DNSRecordDisplay value={row.original.name} />,
  },
  {
    accessorKey: "value",
    header: "Value",
    cell: ({ row }: any) => <DNSRecordDisplay value={row.original.value} />,
  },
  {
    accessorKey: "priority",
    header: "Priority",
    cell: ({ row }: any) => <DNSRecordDisplay value={row.original.priority} />,
  },
  {
    accessorKey: "status",
    header: "Verified",
    cell: ({ row }: any) => {
      switch (row.original.status) {
        case DNSRecordStatus.success:
          return <CheckCircledIcon className="w-4 h-4 text-green-500" />;
        case DNSRecordStatus.optional:
          return (
            <Tooltip>
              <TooltipTrigger>
                <InfoCircledIcon className="w-4 h-4 text-yellow-500" />
              </TooltipTrigger>
              <TooltipContent className="max-w-[600px]">
                The DMARC record is optional and not required to send emails.
                Nevertheless, for deliverability and spam prevention purposes,
                we recommend adding it. You do not need to use the exact value
                provided here, see{" "}
                <a
                  href="https://www.cloudflare.com/learning/dns/dns-records/dns-dmarc-record"
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:text-blue-500"
                >
                  here
                </a>{" "}
                for more information.
              </TooltipContent>
            </Tooltip>
          );
        default:
          return <CrossCircledIcon className="w-4 h-4 text-red-500" />;
      }
    },
  },
];

const DNSRecordDisplay = (props: { value: string | number | null }) => {
  if (!props.value) {
    return null;
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(props.value!.toString());
    toast.success("Copied to clipboard");
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="text-xs truncate text-ellipsis w-[200px]">
        {props.value}
      </div>
      <Button
        variant="ghost"
        size="icon"
        className="h-6 w-6"
        onClick={handleCopy}
      >
        <CopyIcon className="w-4 h-4" />
      </Button>
    </div>
  );
};

const DNSRecordMobileDisplay = (props: {
  name: string;
  value: string | number | null;
}) => {
  if (!props.value) {
    return null;
  }
  return (
    <div className="flex items-center space-x-2 w-full">
      <div className="text-xs font-bold">{props.name}</div>
      <DNSRecordDisplay value={props.value} />
    </div>
  );
};

const DomainDNSRecordsDialog = (props: {
  domain: Domain;
  setDomain: React.Dispatch<React.SetStateAction<Domain | null>>;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog
      open={props.open}
      onOpenChange={(open) => {
        if (!open) {
          props.setDomain(null);
        }
        props.setOpen(open);
      }}
    >
      <DialogContent className="max-w-[95%]">
        <DialogHeader>
          <DialogTitle>Domain DNS Records</DialogTitle>
          <DialogDescription>
            Please add these records to your DNS provider.
          </DialogDescription>
          <DataTable
            data={props.domain.dns_records}
            columns={dnsColumns}
            hidePagination={true}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const DomainDnsRecordsDrawer = (props: {
  domain: Domain;
  setDomain: React.Dispatch<React.SetStateAction<Domain | null>>;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Drawer
      open={props.open}
      onOpenChange={(open) => {
        if (!open) {
          props.setDomain(null);
        }
        props.setOpen(open);
      }}
    >
      <DrawerContent className="h-[90%]">
        <DrawerHeader>
          <DrawerTitle>Domain DNS Records</DrawerTitle>
          <DrawerDescription>
            Please add these records to your DNS provider.
          </DrawerDescription>
        </DrawerHeader>
        {props.domain.dns_records.map((record) => (
          <div
            key={record.name + record.type}
            className="border-b border-gray-200 p-4 cursor-pointer"
          >
            <DNSRecordMobileDisplay name="Type" value={record.type} />
            <DNSRecordMobileDisplay name="Name" value={record.name} />
            <DNSRecordMobileDisplay name="Value" value={record.value} />
            <DNSRecordMobileDisplay name="Priority" value={record.priority} />
          </div>
        ))}
      </DrawerContent>
    </Drawer>
  );
};

export const DomainRecordsPopup = (props: {
  domain: Domain;
  setDomain: React.Dispatch<React.SetStateAction<Domain | null>>;
}) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.domain) {
      setOpen(true);
    }
  }, [props.domain]);

  return isMobile ? (
    <DomainDnsRecordsDrawer
      domain={props.domain}
      setDomain={props.setDomain}
      open={open}
      setOpen={setOpen}
    />
  ) : (
    <DomainDNSRecordsDialog
      domain={props.domain}
      setDomain={props.setDomain}
      open={open}
      setOpen={setOpen}
    />
  );
};

const AddDomainDialog = (props: {
  onSubmitSuccessCallback: (domain: Domain) => void;
  children: React.ReactNode;
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="h-4 w-4 mr-2" /> Add Custom Domain
        </Button>
      </DialogTrigger>
      <DialogContent
        className="max-w-[600px] max-h-[90%] overflow-y-scroll"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Add a Custom Domain</DialogTitle>
        </DialogHeader>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

const AddDomainDrawer = (props: {
  onSubmitSuccessCallback: (domain: Domain) => void;
  children: React.ReactNode;
}) => {
  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button>
          <PlusIcon className="h-4 w-4 mr-2" /> Add Custom Domain
        </Button>
      </DrawerTrigger>
      <DrawerContent className="h-[90%]">
        <DrawerHeader>
          <DrawerTitle>Add a Custom Domain</DrawerTitle>
        </DrawerHeader>
        <div className="p-4 space-y-5 overflow-y-auto">{props.children}</div>
      </DrawerContent>
    </Drawer>
  );
};

export const AddDomainPopup = (props: {
  onSubmitSuccessCallback: (domain: Domain) => void;
}) => {
  const isMobile = useIsMobile();
  const children = (
    <DomainForm onSubmitSuccessCallback={props.onSubmitSuccessCallback} />
  );
  return isMobile ? (
    <AddDomainDrawer
      onSubmitSuccessCallback={props.onSubmitSuccessCallback}
      children={children}
    />
  ) : (
    <AddDomainDialog
      onSubmitSuccessCallback={props.onSubmitSuccessCallback}
      children={children}
    />
  );
};
