import { createContext, useContext, useEffect, useState } from "react";
import { InboxConfiguration, UserInfo } from "../types";
import { useAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import {
  getHasApiKey,
  getInboxConfigurations,
  getUserInfo,
} from "@/utils/apiCalls";
import { toast } from "sonner";

interface UserContextProps {
  hasApiToken: boolean;
  userInfo: UserInfo | null;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  inboxes: InboxConfiguration[] | null;
  setInboxes: React.Dispatch<React.SetStateAction<InboxConfiguration[] | null>>;
  inboxLoading: boolean;
}

const UserContext = createContext<UserContextProps>({
  hasApiToken: false,
  inboxes: null,
  setInboxes: () => {},
  inboxLoading: false,
  userInfo: null,
  setUserInfo: () => {},
});

export const UserProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [hasApiToken, setHasApiToken] = useState<boolean>(false);
  const [inboxLoading, setInboxLoading] = useState<boolean>(false);
  const [inboxes, setInboxes] = useState<InboxConfiguration[] | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    if (authInfo.isLoggedIn) {
      if (import.meta.env.VITE_ENV === "prod") {
        Sentry.setUser({
          email: authInfo.user!.email,
          id: authInfo.user!.userId,
        });
      }
    }

    setInboxLoading(true);
    getInboxConfigurations(authInfo.accessToken ?? null).then((response) => {
      if (response) {
        setInboxes(response);
      } else {
        toast.error(
          "Failed to fetch inboxes, please refresh the page and try again"
        );
      }
      setInboxLoading(false);
    });

    getHasApiKey(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setHasApiToken(response.has_api_key);
      } else {
        toast.error(
          "Failed to fetch API keys, please refresh the page and try again"
        );
      }
    });

    getUserInfo(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setUserInfo(response);
      } else {
        toast.error(
          "Failed to fetch user info, please refresh the page and try again"
        );
      }
    });
  }, [authInfo.isLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        hasApiToken,
        inboxes,
        setInboxes,
        inboxLoading,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
